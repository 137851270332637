const WhoAmI = () => {
  return (
    <>
        <div className="flex justify-center">
            <h4 className="text-2xl font-mono mb-6 mx-auto">¿Quién soy?</h4>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center">
            <div>
            <img src="/me/cj.webp" alt="avatar" className="w-32 h-32 rounded-full mb-8 lg:mb-0" />
            </div>
            <div className="flex-1 text-sm font-mono sm:text-xl ml-0 md:ml-8">
                <h2 className="my-4">Soy Carles Javierre, un joven emprendedor de Barcelona al que le apasiona el mundo de la tecnología. Empecé a programar a los 12 años, y desde entonces sólo he hecho que ir a más. Reciclándome constantemente y aprendiendo tecnologías nuevas, consigo dar un servicio excepcional cuidando hasta el último detalle.</h2>
                <h2 className="my-4">Actualmente me centro en DevOps, observabilidad y la Ciberseguridad, pero siempre abierto a nuevos frentes.</h2>
            </div>
        </div>
    </>
  );
};

export default WhoAmI;