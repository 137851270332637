import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import Card from '@/components/Card'
import CardSmall from '@/components/CardSmall'
import Button from '@/components/Button'
import ButtonA from '@/components/ButtonA'
import WhatDoIDo from '@/components/partials/WhatDoIDo'
import WhoAmI from '@/components/partials/WhoAmI'
import Partnership from '@/components/Partnership'
import Header from '@/components/partials/Header'
import TypedComponent from '@/components/TypedComponent'
import Link from 'next/link'


const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>Carles Javierre | DevOps</title>
        <meta name="description" content="DevOps, Observabilidad y ciberseguridad al alcance de tu empresa" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" sizes="any"/>

        <meta property="og:title" content="Carles Javierre - DevOps"/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content="DevOps, Observabilidad y ciberseguridad al alcance de tu empresa"/>
        <meta property="og:url" content="https://carlesjavierre.com"/>
        <meta property="og:image" content="/me/cj.webp"/>
        <meta property="og:site_name" content="Carles Javierre"/>

        <meta property="twitter:title" content="Carles Javierre - DevOps"/>
        <meta property="twitter:description" content="DevOps, Observabilidad y ciberseguridad al alcance de tu empresa"/>
        <meta property="twitter:image" content="/me/cj.webp"/>
      </Head>
      <main>

        <Header currentTab='none'/>

        <div>
              <div className="container mx-auto p-4 py-16">
                  <div className="py-12 border-b border-gray-300">
                      <div className="flex justify-center h-40 py-16">
                          {/* <typed className="text-4xl antialiased font-mono text-primary-dark" v-bind:strings="['DevOps', 'SysAdmin', 'Cloud Architect', 'Cloud Evangelist']"></typed> */}
                        <TypedComponent></TypedComponent>
                      </div>

                      <div className="flex justify-center font-mono py-16">
                          <ButtonA text='Mi perfil Freelance' href='https://www.malt.es/profile/carlesjavierre' title='Malt' />
                      </div>
                  </div>

                  <div className="py-16 px-8 text-justify md:text-left md:px-10 lg:px-40 border-b border-gray-300 font-sans justify-center" id="about">
                      <WhoAmI/>
                  </div>

                  <div className="py-16 md:px-16 lg:px-40 border-b border-gray-300 font-mono" id="services">
                      <WhatDoIDo/>
                  </div>

                  <div className="py-16 border-b border-gray-300 font-mono" id="technologies">
                      <div className="flex justify-center">
                          <h3 className="text-bold text-2xl py-6">¿Qué tecnologías uso?</h3>
                      </div>
                      <div className="flex flex-wrap justify-center py-8 ">
                          <Card title='Kubernetes (& Docker)' text='Entornos immutables y documentados. Escalabilidad, seguridad y resiliencia. Adéntrate en el futuro haciéndolo tu presente' image='/external/Kubernetes.svg' alt='Kubernetes' />
                          <Card title='AWS' text='El proveedor Cloud por excelencia donde podrás crecer sin límite. Un Cloud real y premium que no defrauda' image='/external/AWS.png' alt='Amazon Web Services' />
                          <Card title='Linux' text='El sistema más eficiente, estable y seguro. Incluso el propio gigante y competencia Microsoft lo dice' image='/external/Tux.svg' alt='Linux' />
                      </div>
                      <div className="flex justify-center">
                          <h5 className="text-bold text-2xl py-6">¿Sólo eso?</h5>
                      </div>
                      <div className="flex justify-center text-center">
                          <h5 className="text-bold text-lg py-6">Realmente utilizo muchísimas, pero la lista sería muy larga. Éstas son las más importantes con las que trabajo.</h5>
                      </div>
                      {/* <div className="flex justify-center text-center">
                          <h5 v-if="isOffline" className="text-bold text-lg py-6">Incluyendo que estás viendo la web sin conexión, y funciona incluso recargando la página. ¿Curioso, verdad?</h5>
                      </div> */}
                      <div className="flex flex-wrap justify-center py-8 ">
                          <CardSmall title='Cloudflare' text='Protección DDoS, WAF y ZeroTrust a gran escala' image='/partners/Cloudflare.png' alt='Cloudflare' />
                          <CardSmall title='DataDog' text='Observabilidad y monitoreo redefinidos' image='/partners/Datadog.svg' alt='DataDog' />
                          <CardSmall title='SentinelOne' text='Protección EDR de nueva generación con IA' image='/partners/SentinelOne.png' alt='SentinelOne' />
                          <CardSmall title='Docker' text='Consigue el mismo entorno estés donde estés' image='/external/Docker.png' alt='Docker' />
                          <CardSmall title='Gitlab' text='Repositorios y CI/CD con integración en Kubernetes' image='/external/Gitlab.png' alt='Gitlab' />
                          <CardSmall title='NGINX' text='Seguridad, estabilidad y rendimiento web.' image='/external/NGINX.png' alt='NGINX' />
                          <CardSmall title='Acronis' text='Backups fiables en la nube' image='/external/Acronis.png' alt='Acronis' />
                      </div>
                      <div className="flex justify-center">
                          <p className="text-sm py-6 text-gray-500">*Todos los logos de marcas utilizados son propiedad intelectual de las respectivas empresas.</p>
                      </div>
                  </div>

                  <div className="py-16 border-b" id="contact">
                      <div className="flex justify-center">
                          <div className="mx-auto text-primary-dark hover:text-primary transition-all duration-150 ease-in-out">
                              <i className="far fa-3x fa-laugh"></i>
                          </div>
                      </div>
                      <div className="flex justify-center">
                          <h5 className="text-bold text-2xl py-6 font-mono">¿Te he caído bien?</h5>
                      </div>
                      <div className="flex justify-center text-center">
                          <h5 className="text-lg pb-16 font-mono">Te animo a que me mandes un mensaje para ver cómo podemos colaborar. ¡No muerdo!</h5>
                      </div>
                      {/* <div className="flex justify-center">
                          <contactform v-bind:recaptcha_key="'6LeWUbsUAAAAAGa8r73Clt_lMpPjrunLn5ox2sO8'"></contactform>
                      </div> */}
                      <div className="flex justify-center font-mono py-8">
                          <ButtonA text='Ponte en contacto conmigo' href='https://www.malt.es/profile/carlesjavierre' title='Malt' />
                      </div>
                      <div className="flex justify-center text-center font-mono py-8 lg:px-40">
                        <h5 className="text-lg font-mono">También me puedes mandar un correo electrónico a info@ el dominio que estás visitando o mandarme solicitud por <Link className="text-primary" href="https://www.linkedin.com/in/carlesjavierre/">LinkedIn</Link></h5>
                      </div>
                  </div>

                  <div className=" py-16">
                      <div className="flex justify-center pb-8">
                          <h5 className="sm:text-3xl text-2xl antialiased font-mono text-primary-dark">Partner oficial de</h5>
                      </div>
                      <div className="flex flex-wrap justify-center">
                          <Partnership image='/partners/Cloudflare.png' alt='Partner de Cloudflare' />
                          <Partnership image='/partners/Datadog.svg' alt='Partner de DataDog' />
                          <Partnership image='/partners/SentinelOne.png' alt='Partner de SentinelOne' />
                          <Partnership image='/partners/Netelip.png' alt='Partner de Netelip' />
                      </div>
                  </div>

                  {/* <div className="fixed right-0 bottom-0 pb-8 pr-8 sm:pb-16 sm:pr-16 rounded-full">
                      <div className="py-2 px-3 bg-gray-200 border border-gray-300 rounded-full align-center cursor-pointer" v-scroll-to="'#top'">
                          <div className="text-primary"><i className="fas fa-arrow-up"></i></div>
                      </div>
                  </div> */}


              </div>
          </div>

      </main>
    </>
  )
}
